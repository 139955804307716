@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

.box_custom {
  border-radius: 12px;
  border: 1px solid rgba(13, 34, 71, 0.1);

  background: linear-gradient(0deg, #85c9ea -29.04%, #f09cc3 100%);

  box-shadow: 0px -1px 1px 1px rgba(13, 34, 71, 0.1) inset,
    0px 2px 5px 0px rgba(13, 34, 71, 0.08);
  color: #fff;

  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
